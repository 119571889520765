"use client"

import React from 'react';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { generateMetadata } from '@/lib/metadata';

export const metadata = generateMetadata({
  site_name: "Infyways Solutions",
  locale: "en_US",
    title: "Privacy Policy - Infyways",
    description: "Learn more about our company and what we do.",
    keywords: ["about", "company", "services"],
    openGraph: {
      images: ["https://mycompany.com/og-image.jpg"],
      url: "https://mycompany.com/about",
    },
    twitter: {
      images: ["https://mycompany.com/twitter-image.jpg"],
    },
    canonical: "https://mycompany.com/about",
  });

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <motion.div
        className="text-center"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <motion.h1 
          className="text-6xl font-bold text-gray-800 mb-2"
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200, damping: 10 }}
        >
          404
        </motion.h1>
        <motion.p 
          className="text-xl text-gray-600 mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.6 }}
        >
          Oops! The page you're looking for doesn't exist.
        </motion.p>
      </motion.div>
      
      <motion.div
        className="space-x-4"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.6 }}
      >
        <Link href="/" className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300">
          Go Home
        </Link>
        <button
          onClick={() => window.history.back()}
          className="px-6 py-3 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition duration-300"
        >
          Go Back
        </button>
      </motion.div>
      
   
    </div>
  );
};

export default NotFound;